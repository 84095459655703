import React from 'react'
import {
  CFImage,
  CFView,
  CFText,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  hero,
  heroText,
  logo,
  mobileLogo,
  mobileHero,
  mobileHeroText,
} from 'images'
import ViewMenuButton from './ViewMenuButton'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="110vh"
          maxHeight="750px"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          column
          justifyBetween
          alignCenter
        >
          <CFView b w="100%" column center pv="4px" bg="rgba(0,0,0,.7)">
            <CFImage src={mobileLogo} w="70%" maxWidth="350px" alt="About" />
          </CFView>
          <CFView w="100%" textCenter bg="rgba(0,0,0,.7)">
            <CFView ph="7%" mt="8px">
              <CFText xBold white style={{ fontSize: 30 }}>
                Korean Street Food with a Modern Flair
              </CFText>
            </CFView>
            <CFView pulsate textCenter pv="8px" w="100%">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="650px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView row center bg="rgba(0,0,0,.8)" ph="60px" pv="2px">
            <CFImage src={heroText} w="95%" maxWidth="520px" alt="heroText" />
            <CFView row center mt="5px">
              <CFView ml="30px" pulsate>
                <OrderPickupButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
